import { setSelectedTab } from "../App";
import './Projects.css';
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { ProjectCard } from "./Projects";
import { ProjectSpacing } from "./Projects";
import { Link } from 'react-router-dom';

import ff_handOnChest from '../assets/projects-assets/farmer-frog/handOnChest.png';
import ff_rig from '../assets/projects-assets/farmer-frog/rig.png';

import md_thumbnail from '../assets/projects-assets/moondust/moondust_thumbnail.png';

class PersonalProjects extends React.Component {
    componentDidMount() {
        setSelectedTab('projects');
    }
    render(){
        return (
            <div className='subpage projectsPage'>
                <Link
                className='projectsHeaderLink' 
                to={`/projects`}>
                    {'\u2190'} back
                </Link>
                <ProjectSpacing/>
                <MoondustCard/>
                <ProjectSpacing/>
                <FarmerFrogCard/>
            </div>
        );
    }
}

function MoondustCard() {
    return (
        <ProjectCard title='Moondust' media={[
            <div key={0}>
                <img src={md_thumbnail} alt='moondust thumbnail'/>
            </div>,
            <div className='iframeWrapper' key={1}>
                <iframe 
                width="100%" 
                height="100%" 
                src="https://www.youtube.com/embed/5Y4akoNn_Uw" 
                title="Moondust - Player Movement Test" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowfullscreen>
                </iframe>
            </div>,
        ]}
        description={[
        <div key={0}>
            A 360{'\u00B0'} asteriod mining game.
            <br/>
            <ul>
                <li>Custom planetary gravity physics system.</li>
                <li>Custom 360{'\u00B0'} navigation system for AI pathfinding, including robust navmesh editing tools.</li>
                <li>Extensive use of multithreading for AI pathfinding logic.</li>
            </ul>
        </div>,
        ]}
        tags={[
            <div key={0}>C#</div>,
            <div key={1}>Unity</div>,
            <div key={2}>Blender</div>,
            <div key={3}>Multithreading</div>,
        ]}/>
    );
}

function FarmerFrogCard() {
    return (
        <ProjectCard title='Farmer Frog' media={[
            <div key={0}>
                <img src={ff_handOnChest} alt='farmer frog with hand on chest'/>
            </div>,
            <div className='iframeWrapper' key={1}>
                <iframe title='farmer frog sketchfab' width="100%" height="100%" src="https://sketchfab.com/models/b25a0d8cf44f4f2fa7509a5593e1aa7a/embed"></iframe>
            </div>,
            <div key={2}>
                <img src={ff_rig} alt='farmer frog rig'/>
                <p className="legend">Fully custom IK rig</p>
            </div>
        ]}
        description={[
        <div key={0}>
            One of my 3D models. 
            <br/>
            Modeled, rigged and animated in Blender, 
            and textured in Substance Painter.
        </div>,
        <br key={1}/>,
        <a key={2} href='https://sketchfab.com/3d-models/farmer-frog-b25a0d8cf44f4f2fa7509a5593e1aa7a' rel='noreferrer' target={'_blank'}>Sketchfab link</a>,
        <br key={3}/>,
        <br key={4}/>,
        <a key={5} href='https://www.artstation.com/andrewrollins' rel='noreferrer' target={'_blank'}>More of my 3D art</a>
        ]}
        tags={[<div key={0}>3D Modeling</div>,<div key={1}>Blender</div>,<div key={2}>Substance Painter</div>,]}/>
    );
}

export default PersonalProjects;