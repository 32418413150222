import React from "react";

class AnimatedSpacing extends React.Component {
    static defaultProps = {
        startHeight: '3em',
        endHeight: '1.5em',
        duration: '1s',
        spacingId: ''
    };

    render() {
        return (
            <div className={`animatedSpacing${this.props.spacingId}`}>
            <style>
            {`
            .animatedSpacing${this.props.spacingId} {
              height: ${this.props.startHeight};
              animation: animatedSpacingKeyframes${this.props.spacingId} ${this.props.duration} ease-in-out forwards;
            }
            @keyframes animatedSpacingKeyframes${this.props.spacingId} {
              from { height: ${this.props.startHeight} }
              to { height: ${this.props.endHeight} }
            }
            `}
            </style>
            </div>
        );
    }
}

export default AnimatedSpacing;