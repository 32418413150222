import { setSelectedTab } from "../App";
import AnimatedSpacing from "./AnimatedSpacing";
import './Projects.css';
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';

import ls_main from '../assets/projects-assets/liteboxer/litesport-main.png';
import ls_homepage from '../assets/projects-assets/liteboxer/litesport-homepage.png';
import ls_st from '../assets/projects-assets/liteboxer/litesport-strength.png';
import ls_md from '../assets/projects-assets/liteboxer/litesport-mittdrills.png';
import ls_st_results from '../assets/projects-assets/liteboxer/litesport-strength-results.png';
import ls_tb from '../assets/projects-assets/liteboxer/litesport-totalbody.png';

import xpo_main from '../assets/projects-assets/xponential/xponentialCard.jpg';


class Projects extends React.Component {
    componentDidMount() {
        setSelectedTab('projects');
    }
    render(){
        return (
            <div className='subpage projectsPage'>
                <LiteboxerCard/>
                <ProjectSpacing/>
                <XponentialCard/>
                <ProjectSpacing/>
                <Link
                className='projectsHeaderLink' 
                to={`/projects/personal`}>
                    personal projects {'\u2192'}
                </Link>
            </div>
        );
    }
}

export function ProjectHeaderSpacing() {
    return (
        <AnimatedSpacing spacingId='5' startHeight='7em' endHeight='4em'/>
    );
}

export function ProjectSpacing() {
    return (
        <AnimatedSpacing spacingId='1' startHeight='10em' endHeight='5em'/>
    );
}

function XponentialCard() {
    return (
        <ProjectCard title='Xponential+' media={[
            <div key={0}>
                <img src={xpo_main} alt='xponential main'/>
            </div>,
        ]}
        description={[
            <div key={0}>Xponential+ brings fitness brands <b>Pure Barre</b>, <b>Club Pilates</b> and <b>Stretch Labs</b> to the world of Virtual and Mixed Reality.</div>,
            <br key={3}/>,
            <a key={4} href='https://www.xponential.plus/' rel='noreferrer' target={'_blank'}>Official Xponential+ website</a>,
            <br key={5}/>,
            <br key={6}/>,
            <a key={7} href='https://www.meta.com/experiences/6337939009635569/' rel='noreferrer' target={'_blank'}>Meta Quest store page</a>
        ]}
        tags={[<div key={0}>C#</div>, <div key={1}>GO</div>, <div key={2}>SQL</div>, <div key={3}>Unity</div>, <div key={4}>Meta Quest</div>, <div key={5}>HLSL</div>]}/>
    );
}

function LiteboxerCard() {
    return (
        <ProjectCard title='Litesport' media={[
            <div key={0}>
                <img src={ls_main} alt='litesport main'/>
            </div>,
            <div key={1}>
                <img src={ls_st} alt='litesport st'/>
            </div>,
            <div key={2}>
                <img src={ls_md} alt='litesport md'/>
            </div>,
            <div key={3}>
                <img src={ls_homepage} alt='litesport homepage'/>
            </div>,
            <div key={4}>
                <img src={ls_st_results} alt='litesport results'/>
            </div>,
            <div key={5}>
                <img src={ls_tb} alt='litesport tb'/>
            </div>
        ]}
        description={[
            <div key={0}>Cutting-edge VR fitness on the Meta Quest platform, with a 4-star rating spanning <b style={{color:'#F79376'}}>1,900+</b> user reviews.</div>,
            <br key={1}/>,
            <div key={2}>Roles: VR Lead, Full-Stack Software Engineer</div>,
            <br key={3}/>,
            <a key={4} href='https://litesport.com/pages/vr' rel='noreferrer' target={'_blank'}>Official Litesport website</a>,
            <br key={5}/>,
            <br key={6}/>,
            <a key={7} href='https://www.oculus.com/experiences/quest/4175028349266073/' rel='noreferrer' target={'_blank'}>Meta Quest store page</a>
        ]}
        tags={[<div key={0}>C#</div>, <div key={1}>GO</div>, <div key={2}>SQL</div>, <div key={3}>Unity</div>, <div key={4}>Meta Quest</div>, <div key={5}>HLSL</div>]}/>
    );
}

export class ProjectCard extends React.Component {
    render() {
        return (
            <div className='borderBox projectCard'>
                <Carousel className='mediaCarousel' showThumbs={false} dynamicHeight={true} showStatus={false}>
                    {this.props.media}
                </Carousel>
                <div className='description'>
                    <div>
                        <h3>{this.props.title}</h3>
                        <p>{this.props.description}</p>
                    </div>
                    <div className='tags'>{this.props.tags}</div>
                </div>
            </div>
        );
    }
}

export default Projects;