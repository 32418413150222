import React from "react";
import ContactPanel from "./ContactPanel";
import './Experience.css';
import SnhuLogo from '../assets/images/icons/snhu_logo.svg';
import LitesportLogo from '../assets/images/icons/litesport_logo.svg';
import { setSelectedTab } from "../App";
import { Link } from "react-router-dom";

class ExperienceBullet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            descriptionExpanded: props.startWithDescriptionExpanded
        }
    }

    toggleVisibleDescription = (event) => {
        if (event.target.tagName === 'A' || window.getSelection().toString()) {
            return;
        }
        this.setState(state => ({
            descriptionExpanded: !state.descriptionExpanded
          }));
    }

    render() {
        let animateStyles = {};
        let expandLabelStyles = {};
        let descriptionStyles = {};
        if (this.state.descriptionExpanded) {
            animateStyles.maxHeight = '100em';
            expandLabelStyles.opacity = '0';
            descriptionStyles.opacity = '1';
        } else {
            animateStyles.maxHeight = '1.5em';
            expandLabelStyles.opacity = '1';
            descriptionStyles.opacity = '0';
        }

        return (
        <div className="timelineElementMain greyBox">
            <div className="timelineHeader">
                <h3 className="timelineBulletTitle">{this.props.title}</h3>
                <p className="timelineDate">{this.props.date}</p>
            </div>
            <div className="innerBlackBox animateToContentSize" 
            style={animateStyles}
            onClick={(event)=>this.toggleVisibleDescription(event)}>
                <div className="expandLabelText" style={expandLabelStyles}>[ Click to Expand Description ]</div>
                <div className="descriptionText" style={descriptionStyles}>{this.props.description}</div>
            </div>
        </div>
        );
    }
}

class OrgMarker extends React.Component {
    render(){
        return (
        <div className="orgMarker" style={{borderColor: this.props.borderColor}}>
            <table>
                <tbody>
                    <tr>
                        <td valign="top">
                            <img className="timelineOrgImage" src={this.props.image} alt={`${this.props.name} icon`}/>
                        </td>
                        <td valign="top">
                            <h2 className="timelineTitle">{this.props.name}</h2>
                            <p className="timelineDate">{this.props.date}</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            {this.props.content}
        </div>
        );
    }
}

class Experience extends React.Component {
    componentDidMount() {
        setSelectedTab('experience');
    }
    render() {
        return (
        <div className="subpage">
            <Link 
            className='downloadResumeButton'
            to={`https://andrewrollins.info/resume.pdf`}
            target='_blank'
            download='Andrew Rollins - Software Engineer.pdf'>
                {'\u2193'} download resume
            </Link>
            <OrgMarker name="Litesport" date={`June 2019 - Present (${timeSinceDate(new Date("06/01/2019"))})`} image={LitesportLogo} borderColor='#027f21' content={
                <div>
                    <ExperienceBullet title='VR Lead' date='August 2022 - Present' description={[
                    <h4 key={0}>Feature Planning and Development Management</h4>,
                    <p key={1}>Managed multiple concurrent VR and AR projects in a fast-paced environment. Planned feature implementation and application sprints/iterations. Ensured the timely and efficient development of deliverables through weekly planning meetings and ticket assignments that played to each engineer's strengths.</p>,
                    <h4 key={3}>Full Stack Development</h4>,
                    <p key={4}>Became the first full-stack dev at Litesport. Designed and implemented database schema and RESTful server API interfaces, utilizing GoLang and PostgreSQL. Frontend development included working on VR and web apps, working primarily in C# and React. Acted as a backend liaison for the frontend devs, answering questions about server API functionality during daily standups, and supporting the development of client API implementation.</p>,
                    <h4 key={5}>Continued previously held responsibilities, such as acting as code owner.</h4>
                    ]}></ExperienceBullet>
        
                    <ExperienceBullet title='Software Engineer' date='June 2019 - August 2022' description={[
                    <h4 key={0}>System Design and Implementation</h4>,
                    <p key={1}>
                        Designed and developed comprehensive VR input, UI and server API systems, simplifying the development process and reducing code redundancy through modular structures. Focused on optimizing the performance of these systems for the hardware constraints of the Meta Quest headset, by implementing techniques such as multithreading, offloading intensive client logic to the server, and minimizing data transfer between client and server.
                    </p>,
                    <h4 key={2}>Process Management</h4>,
                    <p key={3}>
                        Worked closely with both internal and external QA teams to ensure robust functionality within tight deadlines. Acted as code owner and repository manager for the VR project, reviewing pull requests and managing build distribution. Planned and executed a parallel-build development process that streamlined the implementation of a UX overhaul and minimized merge conflicts with the actively-developed released version. 
                    </p>,
                    <h4 key={4}>Prototyping</h4>,
                    <p key={5}>
                        Listed as an inventor on two software patents. Conceptualized and prototyped several experimental interaction and gameplay systems for VR applications.
                    </p>
                    ]}></ExperienceBullet>
                </div>
            }></OrgMarker>

            <OrgMarker name="Southern New Hampshire University" date="Class of 2021" image={SnhuLogo} borderColor='#00244E' content={
                <ExperienceBullet title='Double Major, Minor - 3.9 GPA' startWithDescriptionExpanded={true} description={
                <section>
                    <h4>Major in Game Programming and Development</h4>
                    <ul>
                        <li>Received the <a href='https://drive.google.com/file/d/1IeuAlGs7BOLWao--khv-PCzgRVkcLZ9t/view?usp=sharing' target="_blank" rel='noreferrer'>Outstanding Student</a> award</li>
                    </ul>
                    <h4>Major in Game Art and Development</h4>
                    <h4>Minor in Applied Mathematics</h4>
                    <p><a href="https://meritpages.com/AndrewRollins" target="_blank" rel='noreferrer'>6 merit-based President List awards</a></p>
                </section>
                }></ExperienceBullet>
            }></OrgMarker>
            <ContactPanel style={{margin: '4em auto 1em', maxWidth: '50em'}}></ContactPanel>
        </div>
        );
    }
}

function timeSinceDate(startDate) {
    let now = new Date();
    let totalMonths = 
        (now.getFullYear() - startDate.getFullYear()) * 12 
        + now.getMonth() - startDate.getMonth();
    
    let months = totalMonths % 12;
    let years = Math.floor(totalMonths / 12);

    let monthString = months > 0 ? `${months} month${months > 1 ? "s" : ""}` : "";
    let yearString = years > 0 ? `${years} year${years > 1 ? "s" : ""}` : "";
    return `${yearString}${yearString !== "" ? ", " : ""}${monthString}`
}

export default Experience;