import React from 'react';
import ContactPanel from './ContactPanel';
import AnimatedSpacing from './AnimatedSpacing';
import './About.css';
import Headshot from '../assets/images/headshot.jpg';
import { setSelectedTab } from '../App';

class About extends React.Component {
    componentDidMount() {
        setSelectedTab('about');
    }
    render(){
        return (
        <div className="subpage" id="aboutMeSubpage">
            <div className="aboutMeHalf aboutMeLeftHalf">
                <img id="headshot" src={Headshot} alt='headshot'/>
                <div id="specialties">
                    <CodeSpecialty text='C#'            delay ='0.7s'></CodeSpecialty>
                    <CodeSpecialty text='C++'           delay ='0.8s'></CodeSpecialty>
                    <CodeSpecialty text='GO'            delay ='0.9s'></CodeSpecialty>
                    <CodeSpecialty text='SQL'           delay ='1s'></CodeSpecialty>
                    <CodeSpecialty text='React Native'  delay ='1.1s'></CodeSpecialty>
                    <CodeSpecialty text='JS'            delay ='1.2s'></CodeSpecialty>
                </div>
            </div>
            <div className="aboutMeHalf aboutMeRightHalf">
                <h1 className='noBottomMargin'>Hi, I'm Andrew Rollins</h1>
                <AnimatedSpacing/>
                <h3 className='noVerticalMargin'>Software Engineer and Team Leader</h3>
                <AnimatedSpacing/>
                <p className='noTopMargin'>Technical leader, skilled in project management and facilitating effective work environments. Full-stack developer, proficient in C# and GO, multithreaded development and VR/AR design.</p>
                <ContactPanel/>
            </div>
        </div>
        );
    }
}

class CodeSpecialty extends React.Component {
    render() {
        return (
        <h2 className='gradientText codeSpecialty' 
            style={{animationDelay: `${this.props.delay}`}}>
            {this.props.text}
        </h2>);
    }
}

export default About;