import $ from "jquery";

class Action
{
    add(f)
    {
        if (this.invocationArray === undefined) this.invocationArray = [];
        this.invocationArray[this.invocationArray.length] = f;
    }
    
    invoke()
    {
        for(let i = 0; i < this.invocationArray.length; i+=1)
        {
            this.invocationArray[i]();
        }
    }
}

function getStringFromFile(path, onComplete)
{
    $.ajax({
        url: path,
        type: "get",
        success: onComplete
    });
}

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default Action;
export { getStringFromFile };
export { timeout };