import React from 'react';
import $ from 'jquery';
import './ContactPanel.css'
import EmailIcon from '../assets/images/icons/email.svg';
import PhoneIcon from '../assets/images/icons/phone.svg';
import LinkedInLogo from '../assets/images/icons/linkedin_logo.svg';

class ContactRow extends React.Component {
    render() {
        return (
        <div className="contactRow">
            <img src={this.props.image} alt='contact icon'/>
            <p>{this.props.title}</p>
        </div>
        );
    }
}

class LinkedinIcon extends React.Component {
    render() {
        return (
        <div className="linkedinIcon" onClick={goToLinkedin} onMouseEnter={enterHoverLinkedin} onMouseLeave={exitHoverLinkedin}>
            <img src={LinkedInLogo} alt='linkedin icon'/>
        </div>
        );
    }
}

class ContactPanel extends React.Component {
    render() {
        return (
        <div className="contactPanel" style={this.props.style}>
            <div className='contactPanelInner'>
                <h2 className='contactPanelTitle'>contact</h2>
                <ContactRow title="andrewrollinsdev@gmail.com" image={EmailIcon}></ContactRow>
                <div className="phoneRow">
                    <ContactRow title="978 417 2548" image={PhoneIcon}></ContactRow>
                    <LinkedinIcon></LinkedinIcon>
                </div>
            </div>
        </div> 
        );
    }
}

function goToLinkedin() {
    window.open(`https://www.linkedin.com/in/andrew-rollins-44a98413a/`);
}

function enterHoverLinkedin() {
    animateLinkedinIcon('#0072b1', '2.75em');
}

function exitHoverLinkedin() {
    animateLinkedinIcon('black', '2.5em');
}

function animateLinkedinIcon(color, size)
{
    var ele = $('.linkedinIcon');
    var logo = ele.children(":first");
    ele.animate({ 'backgroundColor':color }, "fast");
    logo.animate({ 'width':size, 'height':size }, "fast");
}

export default ContactPanel;