import './App.css';
import { HashRouter, Route, Routes, Link, Navigate } from 'react-router-dom';
import About from './components/About';
import Projects from './components/Projects';
import PersonalProjects from './components/PersonalProjects';
import Experience from './components/Experience';
import React from 'react';
import Action from './general';
import { useEffect, useState } from "react";

const onPageChanged = new Action();
var currentPage = 'about';

function setSelectedTab(tab) {
  console.log(`set tab to ${tab}`);
  currentPage = tab;
  onPageChanged.invoke();
  window.scrollTo(0, 0);
}

function App() {
  const headerRef = React.createRef();
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    const applyHeaderHeight = () => {
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight);
      }
    };
    applyHeaderHeight();
    window.addEventListener('resize', applyHeaderHeight);
    return () => window.removeEventListener('resize', applyHeaderHeight);
  }, [headerRef]);

  return (
    <HashRouter>
      <div className="app">
        <DesktopHeader refProp={headerRef}/>
        <div className='headerShadow' style={{top:headerHeight}}></div>
        <div style={{height:headerHeight}}></div>
        <div style={{height:'2em'}}></div>
        <Routes>
          <Route exact path="/" element={<Navigate to="/about" />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/personal" element={<PersonalProjects />} />
          <Route path="/experience" element={<Experience />} />
        </Routes>
      </div>
    </HashRouter>
  );
}

class DesktopHeader extends React.Component {
  render() {
    return (
      <div className='desktopHeader' ref={this.props.refProp}>
        <div className='gradientText'>{'{ Andrew Rollins }'}</div>
        <nav>
          <DesktopHeaderItem page='about'/>
          <DesktopHeaderItem page='projects'/>
          <DesktopHeaderItem page='experience'/>
        </nav>
      </div>
    );
  }
}

class DesktopHeaderItem extends React.Component {
  textRef = React.createRef();

  constructor(props){
    super(props);
    onPageChanged.add(this.handleTabChanged);
  }

  handleTabChanged = () => {
    if (this.textRef.current) {
      if (currentPage === this.props.page) {
        this.textRef.current.classList.add('selected');
      }
      else{
        this.textRef.current.classList.remove('selected');
      }
    }
  }

  componentDidMount() {
    // update the selected tab on mount
    this.handleTabChanged();
  }

  render() {
    return (
      <Link 
        ref={this.textRef} 
        className='desktopHeaderItem' 
        to={`/${this.props.page}`}>
        {this.props.page}
      </Link>
    );
  }
}

export default App;
export { setSelectedTab };
